<template>
    <div v-if="view.isLoaded==true" class="con-wrap">
        <LeadMCRViewPopup :idx="pop.idx" :idx_hq="pop.idx_hq" v-if="pop.is_show_lmcr" @close="pop.is_show_lmcr=false"></LeadMCRViewPopup>
        <MCRViewPopup :idx="pop.idx" :idx_hq="pop.idx_hq" v-if="pop.is_show_mcr" @close="pop.is_show_mcr=false"></MCRViewPopup>
        <MCGViewPopup :idx="pop.idx" :idx_hq="pop.idx_hq" v-if="pop.is_show_mcg" @close="pop.is_show_mcg=false"></MCGViewPopup>

        <CarrotTitle :title="view.info.ename+'('+view.info.kname+') 인사카드'"></CarrotTitle>
        <div class="board">
            <div class="mt-40">

                <div class="tabDefult-col">
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab1-idx', params : { idx:view.idx }}"><button class="tab-default">인적사항</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab2-idx', params : { idx:view.idx }}"><button class="tab-default">인사History</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab3-idx', params : { idx:view.idx }}"><button class="tab-default on">평가</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab4-idx', params : { idx:view.idx }}"><button class="tab-default">휴가</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab5-idx', params : { idx:view.idx }}"><button class="tab-default">교육</button></router-link>
                    <!-- <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab6-idx', params : { idx:view.idx }}"><button class="tab-default">권한</button></router-link> -->
                </div>

                <div>

                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>이름</th>
                                <td>
                                    {{ view.info.kname }} ({{ view.info.ename }})
                                </td>
                            </tr>
                            <tr>
                                <th>인/적성평가</th>
                                <td>
                                    <!-- 파일 등록 후 -->
                                    <div class="dp-inblock mr-20" v-if="view.evaluation.disc.name">
                                        <a :href="view.evaluation.disc.path" target="_blank"><span class="btn-view ver-middle mr-10">{{ view.evaluation.disc.name }}</span></a>
                                        <button class="btn-del dp-inblock ver-middle" @click="mod.deleteEvFile('DISC')">X</button>
                                    </div>
                                    <!-- 파일 등록 전 -->
                                    <button class="btn-default dp-inblock h-30px mr-20" v-else>
                                        <span>DISC 결과 등록</span>
                                        <carrot-file-uploader maxsize="1" ref="upfile1" @change="mod.uploadFile('upfile1')" class="up-file"></carrot-file-uploader>
                                    </button>

                                    <div class="dp-inblock mr-20" v-if="view.evaluation.mbti.name">
                                        <a :href="view.evaluation.mbti.path" target="_blank"><span class="btn-view ver-middle mr-10">{{ view.evaluation.mbti.name }}</span></a>
                                        <button class="btn-del dp-inblock ver-middle" @click="mod.deleteEvFile('MBTI')">X</button>
                                    </div>
                                    <button class="btn-default dp-inblock h-30px mr-20" v-else>
                                        <span>MBTI 결과 등록</span>
                                        <carrot-file-uploader maxsize="1" ref="upfile2" @change="mod.uploadFile('upfile2')" class="up-file"></carrot-file-uploader>
                                    </button>

                                    <div class="dp-inblock mr-20" v-if="view.evaluation.gcat.name">
                                        <a :href="view.evaluation.gcat.path" target="_blank"><span class="btn-view ver-middle mr-10">{{ view.evaluation.gcat.name }}</span></a>
                                        <button class="btn-del dp-inblock ver-middle" @click="mod.deleteEvFile('GCAT')">X</button>
                                    </div>
                                    <button class="btn-default dp-inblock h-30px mr-20" v-else>
                                        <span>GCAT 결과 등록</span>
                                        <carrot-file-uploader maxsize="1" ref="upfile3" @change="mod.uploadFile('upfile3')" class="up-file"></carrot-file-uploader>
                                    </button>

                                    <div class="dp-inblock mr-20" v-if="view.evaluation.enneagram.name">
                                        <a :href="view.evaluation.enneagram.path" target="_blank"><span class="btn-view ver-middle mr-10">{{ view.evaluation.enneagram.name }}</span></a>
                                        <button class="btn-del dp-inblock ver-middle" @click="mod.deleteEvFile('ENNEAGRAM')">X</button>
                                    </div>
                                    <button class="btn-default dp-inblock h-30px mr-20" v-else>
                                        <span>에니어그램 결과 등록</span>
                                        <carrot-file-uploader maxsize="1" ref="upfile4" @change="mod.uploadFile('upfile4')" class="up-file"></carrot-file-uploader>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th>역량평가</th>
                                <td>
                                    <div>
                                        <div v-if="view.evaluation.probation_mid.name">
                                            <a :href="view.evaluation.probation_mid.path" target="_blank"><span class="btn-view ver-middle mr-10">{{ view.evaluation.probation_mid.name }}</span></a>
                                            <button class="btn-del dp-inblock ver-middle" @click="mod.deleteEvFile('PROMID')">X</button>
                                        </div>
                                        <div v-else>
                                            <button class="btn-default dp-inblock h-30px">
                                                <span>Probation 중간평가 결과 등록</span>
                                                <carrot-file-uploader maxsize="1" ref="upfile5" @change="mod.uploadFile('upfile5')" class="up-file"></carrot-file-uploader>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="mt-10">
                                        <div v-if="view.evaluation.probation_360.name">
                                            <a :href="view.evaluation.probation_360.path" target="_blank"><span class="btn-view ver-middle mr-10">{{ view.evaluation.probation_360.name }}</span></a>
                                            <button class="btn-del dp-inblock ver-middle" @click="mod.deleteEvFile('PRO360')">X</button>
                                        </div>
                                        <div v-else>
                                            <button class="btn-default dp-inblock h-30px">
                                                <span>Probation 360도 평가 결과 등록</span>
                                                <carrot-file-uploader maxsize="1" ref="upfile6" @change="mod.uploadFile('upfile6')" class="up-file"></carrot-file-uploader>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="block-title mt-50 mb-20">PBC</div>
                    <table class="table-col w-450px">
                        <colgroup>
                            <col width="80">
                            <col width="130">
                            <col width="180">
                            <col width="160">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>작성일</th>
                                <th>PBC</th>
                                <th>상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.pbc.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.wdate }}</td>
                                <td>
                                    <router-link :to="{ name : 'myPIMS-PBCView-idx', params : { idx:irow.idx }}" target="_blank"><span class="btn-view" @click="view.pbc.selected=irow.idx">{{ irow.title }}</span></router-link>
                                </td>
                                <td>{{ irow.state_txt }}</td>
                            </tr>
                            <tr v-if="view.mcg.total==0">
                                <td colspan="5">등록된 PBC 평가가 없습니다.</td>
                            </tr>
                            <!-- <tr>
                                <td>00</td>
                                <td>2019-00-00</td>
                                <td><span class="btn-view">2019 PBC</span></td>
                                <td>1차 평가완료</td>
                            </tr>
                            <tr>
                                <td>00</td>
                                <td>2019-00-00</td>
                                <td><span class="btn-view">2019 PBC</span></td>
                                <td>2차 평가완료</td>
                            </tr> -->
                        </tbody>
                    </table>
                    
                    <div class="block-title mt-50 mb-20">리드 MCR</div>
                    <table class="table-col w-580px">
                        <colgroup>
                            <col width="130">
                            <col width="150">
                            <col width="150">
                            <col width="150">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>MCR</th>
                                <th>리더십 평균</th>
                                <th>총평균</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(irow, i) in view.mcr.list" :key="i">
                                <tr>
                                    <td>{{ irow.wyear }}</td>
                                    <td>{{ irow.avg_leadership>0?irow.avg_leadership:'-' }}</td>
                                    <td>{{ irow.avg_leadership>0?irow.avg_leadership:'-' }}</td>
                                    <td>
                                        <button class="btn-toggle dp-inblock" v-if="view.mcr.lead_selected==irow.wyear" @click="view.mcr.lead_selected=0">▼</button>
                                        <button class="btn-toggle dp-inblock" v-else @click="view.mcr.lead_selected=irow.wyear">▲</button>
                                    </td>
                                </tr>
                                <!-- 2019 내용 (샘플) -->
                                <tr class="in-table" v-if="view.mcr.lead_selected==irow.wyear">
                                    <td colspan="4">
                                        <dl class="table-dl">
                                            <div class="dl-head">
                                                <dt class="w-130px">작성일</dt>
                                                <dt class="w-150px">리더십</dt>
                                                <dt class="w-150px">총평균</dt>
                                                <dt class="w-150px">연 평균</dt>
                                            </div>
                                            <div class="dl-body" v-for="jrow in irow.list" :key="jrow.idx">
                                                <dd class="w-130px"><span class="btn-view" @click="pop.showLMCR(jrow.idx)">{{ jrow.regdate }}</span></dd>
                                                <dd class="w-150px">{{ jrow.avg_leadership>0?jrow.avg_leadership:'-' }}</dd>
                                                <dd class="w-150px">{{ jrow.avg_leadership>0?jrow.avg_leadership:'-' }}</dd>
                                                <dd class="w-150px dd-fix">{{ irow.avg_total>0?irow.avg_total:'-' }}</dd><!--연평균값-->
                                            </div>
                                            <div class="dl-body" v-if="irow.total==0">
                                                <dd class="w-570px">등록한 리드 MCR 평가가 없습니다.</dd>
                                            </div>
                                        </dl>
                                    </td>
                                </tr>
                                <!-- //2019 내용 -->
                            </template>
                            <tr v-if="view.mcr.total==0">
                                <td colspan="4">등록된 리드 MCR 평가가 없습니다.</td>
                            </tr>
                            <!-- <tr>
                                <td>2018</td>
                                <td>3.5</td>
                                <td>3</td>
                                <td><button class="btn-toggle dp-inblock">▼</button></td>
                            </tr> -->
                        </tbody>
                    </table>
                    
                    <div class="block-title mt-50 mb-20">MCR</div>
                    <table class="table-col">
                        <colgroup>
                            <col width="180">
                            <col width="180">
                            <col width="180">
                            <col width="180">
                            <col width="180">
                            <col width="180">
                            <col width="120">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>MCR</th>
                                <th>업무열정 평균</th>
                                <th>업무내용 평균</th>
                                <th>업무태도 평균</th>
                                <th>동료관계 평균</th>
                                <th>총평균</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(irow, i) in view.mcr.list" :key="i">
                                <tr>
                                    <td>{{ irow.wyear }}</td>
                                    <td>{{ irow.avg_energetic>0?irow.avg_energetic:'-' }}</td>
                                    <td>{{ irow.avg_management>0?irow.avg_management:'-' }}</td>
                                    <td>{{ irow.avg_attitude>0?irow.avg_attitude:'-' }}</td>
                                    <td>{{ irow.avg_communion>0?irow.avg_communion:'-' }}</td>
                                    <td>{{ irow.avg_total>0?irow.avg_total:'-' }}</td>
                                    <td>
                                        <button v-if="view.mcr.selected==irow.wyear" class="btn-toggle dp-inblock" @click="view.mcr.selected=0">▼</button>
                                        <button v-else class="btn-toggle dp-inblock" @click="view.mcr.selected=irow.wyear">▲</button>
                                    </td>
                                </tr>
                                <!-- 2019 내용 (샘플) -->
                                <tr class="in-table" v-if="view.mcr.selected==irow.wyear">
                                    <td colspan="7">
                                        <dl class="table-dl">
                                            <div class="dl-head">
                                                <dt class="w-180px">작성일</dt>
                                                <dt class="w-180px">업무열정</dt>
                                                <dt class="w-180px">업무내용</dt>
                                                <dt class="w-180px">업무태도</dt>
                                                <dt class="w-180px">동료관계</dt>
                                                <dt class="w-180px">총평균</dt>
                                                <dt class="w-120px">연 평균</dt>
                                            </div>
                                            <div class="dl-body" v-for="jrow in irow.list" :key="jrow.idx">
                                                <dd class="w-180px"><span class="btn-view" @click="pop.showMCR(jrow.idx)">{{ jrow.regdate }}</span></dd>
                                                <dd class="w-180px">{{ jrow.avg_energetic>0?jrow.avg_energetic:'-' }}</dd>
                                                <dd class="w-180px">{{ jrow.avg_management>0?jrow.avg_management:'-' }}</dd>
                                                <dd class="w-180px">{{ jrow.avg_attitude>0?jrow.avg_attitude:'-' }}</dd>
                                                <dd class="w-180px">{{ jrow.avg_communion>0?jrow.avg_communion:'-' }}</dd>
                                                <dd class="w-180px">{{ jrow.avg_total>0?jrow.avg_total:'-' }}</dd>
                                                <dd class="w-120px dd-fix">{{ irow.avg_total>0?irow.avg_total:'-' }}</dd><!--연평균값-->
                                            </div>
                                            <div class="dl-body" v-if="irow.total==0">
                                                <dd class="w-1190px">등록한 MCR 평가가 없습니다.</dd>
                                            </div>
                                        </dl>
                                    </td>
                                </tr>
                                <!-- //2019 내용 -->
                            </template>
                            <tr v-if="view.mcr.total==0">
                                <td colspan="7">등록된 MCR 평가가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="block-title mt-50 mb-20">MCG</div>
                    <table class="table-col">
                        <colgroup>
                            <col width="100">
                            <col width="180">
                            <col width="180">
                            <col width="380">
                            <col width="200">
                            <col width="160">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>NO</th>
                                <th>등록연도</th>
                                <th>등록월</th>
                                <th>구분</th>
                                <th>작성일</th>
                                <th>상세</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.mcg.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.wyear }}</td>
                                <td>{{ irow.wmonth }}</td>
                                <td>{{ irow.reviewer_position }}</td>
                                <td>{{ irow.regdate }}</td>
                                <td>
                                    <button v-if="irow.state=='Y'" class="btn-default dp-inblock" @click="pop.showMCG(irow.idx)">보기</button>
                                    <button v-else class="btn-default dp-inblock" @click="pop.modMCG(irow.idx)">임시저장</button>
                                </td>
                            </tr>
                            <tr v-if="view.mcg.total==0">
                                <td colspan="5">등록된 MCG 평가가 없습니다.</td>
                            </tr>
                            <!-- <tr>
                                <td>3</td>
                                <td>2020</td>
                                <td>3</td>
                                <td>Professional 매니저</td>
                                <td>0000-00-00</td>
                                <td><button class="btn-default dp-inblock">보기</button></td>
                            </tr> -->
                        </tbody>
                    </table>
                    
                    <div class="block-title mt-50 mb-20">KPI</div>
                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="150">
                            <col width="150">
                            <col width="150">
                            <col width="150">
                            <col width="150">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>NO</th>
                                <th>작성일</th>
                                <th>KPI</th>
                                <th>업적종합평가</th>
                                <th>역량종합평가</th>
                                <th>상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.kpi.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.wdate }}</td>
                                <td><router-link :to="{ name : 'myPIMS-KPIView-idx', params : { idx:irow.idx }}"><span class="btn-view">{{ irow.title }}</span></router-link></td>
                                <td>{{ irow.performance_ev }}</td>
                                <td>{{ irow.competency_ev }}</td>
                                <td>{{ irow.state_txt }}</td>
                            </tr>
                            <tr v-if="view.kpi.total==0">
                                <td colspan="6">등록된 KPI 평가가 없습니다.</td>
                            </tr>
                            <!-- <tr>
                                <td>0</td>
                                <td>2020</td>
                                <td><span class="btn-view">2018 KPI</span></td>
                                <td>Excellent</td>
                                <td>Excellent</td>
                                <td>2차 평가완료</td>
                            </tr> -->
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="view.goList">목록</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotFileUploader from '@/components/common/CarrotFileUploader.vue'
import LeadMCRViewPopup from '@/components/popup/myPIMS/PersonalInfoList/LeadMCRViewPopup.vue'
import MCRViewPopup from '@/components/popup/myPIMS/MCRViewPopup.vue'
import MCGViewPopup from '@/components/popup/myPIMS/MCGViewPopup.vue'
import Swal from "sweetalert2";
import {useStore} from "vuex";

export default {
    layout:"myPIMS",
    components: {
        CarrotFileUploader,
        LeadMCRViewPopup,
        MCRViewPopup,
        MCGViewPopup
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const upfile1 = ref(null);       // DISC
        const upfile2 = ref(null);       // MBTI
        const upfile3 = ref(null);       // GCAT
        const upfile4 = ref(null);       // ENNEAGRAM

        const upfile5 = ref(null);       // PROMID - probation mid
        const upfile6 = ref(null);       // PRO360 - probation 360
        const store  = useStore();

        const pop = reactive({
            // Popup 노출 여부
            is_show_lmcr : false,
            is_show_mcr  : false,
            is_show_mcg  : false,
            idx : 0,

            showLMCR : (idx) => {
                pop.idx = idx;
                pop.idx_hq = view.idx,
                pop.is_show_lmcr = true;
            },

            showMCR : (idx) => {
                pop.idx = idx;
                pop.idx_hq = view.idx,
                pop.is_show_mcr = true;
            },

            showMCG : (idx) => {
                pop.idx = idx;
                pop.idx_hq = view.idx,
                pop.is_show_mcg = true;
            },
            modMCG : (idx) => {
                sessionStorage.setItem('MCRMCGView', JSON.stringify({ idx:view.idx, syear:view.syear }));
                router.push({
                    name : 'myPIMS-MCGAdd-idx',
                    params : { idx : view.idx, idx_mcg : idx }
                })
            },

        });

        const mod = reactive({

            uploadFile : (id) => {
                if(store.state.pd_auth !=true){
                  Swal.fire({
                    title : '인사카드 상세',
                    text  : '수정 권한이 없습니다.'
                  });
                  return;
                }
                let formData = new FormData();
                formData.append("idx", view.idx);
                switch(id){
                    case 'upfile1':
                        if( upfile1.value.file.filedata !== null ) {
                            formData.append("key", "DISC");
                            formData.append("upfile", upfile1.value.file.filedata);
                        }
                        break;
                    case 'upfile2':
                        if( upfile2.value.file.filedata !== null ) {
                            formData.append("key", "MBTI");
                            formData.append("upfile", upfile2.value.file.filedata);
                        }
                        break;
                    case 'upfile3':
                        if( upfile3.value.file.filedata !== null ) {
                            formData.append("key", "GCAT");
                            formData.append("upfile", upfile3.value.file.filedata);
                        }
                        break;
                    case 'upfile4':
                        if( upfile4.value.file.filedata !== null ) {
                            formData.append("key", "ENNEAGRAM");
                            formData.append("upfile", upfile4.value.file.filedata);
                        }
                        break;
                    case 'upfile5':
                        if( upfile5.value.file.filedata !== null ) {
                            formData.append("key", "PROMID");
                            formData.append("upfile", upfile5.value.file.filedata);
                        }
                        break;
                    case 'upfile6':
                        if( upfile6.value.file.filedata !== null ) {
                            formData.append("key", "PRO360");
                            formData.append("upfile", upfile6.value.file.filedata);
                        }
                        break;
                }

                axios.post("/rest/mypims/upfileEV", formData).then((res) => {
                    if( res.data.err == 0 ){
                        view.evaluation = res.data.evaluation;
                    } else {
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                });
            },

            deleteEvFile : (id) => {
                console.log(id);
                let params = {
                    idx : view.idx,
                    key : id
                };

                axios.post("/rest/mypims/delfileEV", params).then((res) => {
                    if( res.data.err == 0 ){
                        view.evaluation = res.data.evaluation;
                    } else {
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                })
            }
        });

        const view = reactive({
            isLoaded : false,

            idx : 0,

            info : {},

            evaluation : {
                disc:{ name:"" },
                mbti:{ name:"" },
                gcat:{ name:"" },
                enneagram:{ name:"" },
                probation_mid:{ name:"" },
                probation_360:{ name:"" }
            },

            pbc  : {},
            mcr  : {},
            mcg  : {},
            kpi  : {},

            goList : () => {
                router.push({
                    name : "myPIMS-IncumbentManagementList"
                });
            },

            viewTab : (id) => {
                router.push({
                    name   : 'myPIMS-PersonalInfoList_tab' + id + '-idx',
                    params : { idx : view.idx }
                });
            },

            doSearchPBC : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/mypims/getPBCList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.pbc    = res.data;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            doSearchMCR : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/mypims/getMCRGroup", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.mcr    = res.data;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            doSearchMCG : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/mypims/getMCGList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.mcg    = res.data;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            doSearchKPI : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/mypims/getKPIList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.kpi    = res.data;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx_hq : view.idx,
                  user_position: store.state.idx_position,
                  user_sector: store.state.idx_sector,
                  user_office: store.state.idx_office,
                  user_team: store.state.idx_team
                };

                axios.get("/rest/mypims/getHQEvInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info       = res.data.info;
                        view.evaluation = res.data.info.evaluation;

                        //인사카드-tab3 열람권한 체크 추가
                        view.viewAuth=false;
                        if(store.state.isMaster || (view.info.auth.dept && view.info.auth.position)) //마스터권한 또는 (부문/본부/팀+직급 체크)
                          view.viewAuth=true;

                        if(store.state.pd_auth)
                          view.viewAuth=true;

                        if(view.viewAuth==false){
                          router.push({ name : 'main' });
                          Swal.fire({
                            title : '인사카드 상세',
                            text  : '접근 권한이 없습니다.'
                          });
                          return;
                        }

                        view.doSearchPBC();
                        view.doSearchMCR();
                        view.doSearchMCG();
                        view.doSearchKPI();

                        view.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            view.idx = route.params.idx?route.params.idx:0;

            if ( !view.idx ) {
                router.back(-1);
            }

            view.doSearch();
        });

        return {upfile1, upfile2, upfile3, upfile4, upfile5, upfile6, pop, mod, view};
    }
}
</script>

<style lang="scss" scoped>
.btn-default {
    position:relative;
    span {
        cursor:pointer;
    }
    .up-file {
        cursor:pointer; position: absolute; top:0; left:0; width:100%; height:100%; z-index:3; opacity:0.01; overflow:hidden;
    }
}
</style>