<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-900px">
            <div>
                <p class="title">리드 MCR</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light overhidden overscroll maxh-600px">
                <table class="tbl-light-list w-300px">
                    <colgroup>
                        <col width="150">
                        <col width="150">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>작성일</th>
                            <th>리더십</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ pop.mcr.regdate }}</td>
                            <td>{{ pop.mcr.avg_leadership }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="block-title mt-40">MCR 상세내용</div>
                <table class="tbl-light-view mt-10">
                    <colgroup>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>종합 Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="preline">{{ pop.mcr.comment }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="tbl-light-view">
                    <colgroup>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Guide & Check Point</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="preline">{{ pop.mcr.guidencheck }}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
            
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'LeadMCRViewPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        },
        idx_hq: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            idx : 0,
            idx_hq : 0,
            info : {},

            mcr : {
                competency : {
                    energetic  : [],
                    management : [],
                    attitude   : [],
                    communion  : [],
                    leadership : []
                },
                comment     : "",
                guidencheck : ""
            },

            doSearch : () => {
                let params = {
                    idx : pop.idx,
                    idx_hq : pop.idx_hq,
                };

                axios.get('/rest/mypims/getMCRInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data.info;

                        pop.mcr = res.data;

                    } else {
                        console.log(res.data);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx    = props.idx?props.idx:"0";
            pop.idx_hq = props.idx_hq?props.idx_hq:"0";

            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>